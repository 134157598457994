import type { Locale as DateFnsLocale } from 'date-fns'
import { format, formatDistance, formatDistanceStrict, formatRelative } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import zhTW from 'date-fns/locale/zh-TW/index.js'

import { dateFnsLanguageMap } from '@/constants/locales'

const locales: Record<string, DateFnsLocale> = {
  zhTW,
}
const fallbackLocale = zhTW

const getDateFnsLocale: (locale: string) => Promise<Locale> = async (locale: string) => {
  try {
    if (locale.includes('-')) locale = `${locale.split('-')[0]}${locale.split('-')[1].toUpperCase()}`
    if (locales[locale]) return locales[locale]
    const { default: localeModule } = await dateFnsLanguageMap[locale]()
    locales[locale] = localeModule
    return localeModule
  } catch {
    console.warn(`[useI18nDateFns] locale "${locale}" not found, use fallback locale "zhTW" instead`)
    return fallbackLocale
  }
}

type DateFnsFn<T extends any[] = any[]> = (...args: T) => any
type WrapperFunctionType = <T extends DateFnsFn>(fn: T, locale: Locale) => (...args: T extends DateFnsFn<infer U> ? U : any[]) => ReturnType<T>

const generateWrapperFunction: WrapperFunctionType =
  (fn, locale) =>
  (...args) => {
    if (!args[2]) args[2] = {}
    if (!args[2].locale && typeof args[2] !== 'string') args[2].locale = locale
    if (!args[3]) args[3] = {}
    if (!args[3]?.locale) args[3].locale = locale
    args[0] = args[0] instanceof Date && args[0].toString() !== 'Invalid Date' ? args[0] : new Date()
    return fn(...args)
  }

export async function useI18nDateFns() {
  const { locale: i18nLocale } = useI18n()
  const dateFnsLocale = await getDateFnsLocale(i18nLocale.value)
  return {
    dateFnsLocale,
    format: generateWrapperFunction(format, dateFnsLocale),
    formatDistance: generateWrapperFunction(formatDistance, dateFnsLocale),
    formatDistanceStrict: generateWrapperFunction(formatDistanceStrict, dateFnsLocale),
    formatRelative: generateWrapperFunction(formatRelative, dateFnsLocale),
    formatInTimeZone: generateWrapperFunction(formatInTimeZone, dateFnsLocale),
  }
}
